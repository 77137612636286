import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerCarbonCredits:{
    paddingTop:  '120px !important',
   marginBottom: 63,
  },
  buttonCarbonCredits: {
    width: '100%',
    fontFamily: 'Montserrat',
    border: 'none',
    borderRadius: '20px',
    fontSize: '16px',
    textTransform: 'capitalize',
    color: 'white !important',
    backgroundColor: (props) =>
      props.isButtonEnabled ? '#2CDD00' : '#b1b1b1', 
    '&:hover': {
      backgroundColor: (props) =>
        props.isButtonEnabled ? '#28C300' : '#b1b1b1', 
    },
  },
  inputCarbonCredits: {
    background: '#FFF',
    borderRadius: 10,
    color: '#333',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.borderSelect.borderFocus,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
          border: `0.1px solid ${theme.palette.borderSelect.border}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0.1px solid ${theme.palette.borderSelect.border}`,
    },
  },
  paperTableCarbonCredits:{
    marginTop:15,
    marginBottom:15,
    padding: ' 0px 20px 20px 20px' ,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #00DD9E',
    borderRadius: 20,
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
  },
  titleFirstTableCarbonCredits:{
    color: theme.palette.text.secondary,
    margin:'15px 0px',
    fontFamily: 'MontserratSemiBold',
    fontSize: '25px'

  },
  titleSecondTableCarbonCredits:{
    color: theme.palette.text.secondary,
    margin:'15px 0px',
    fontFamily: 'MontserratSemiBold',
    fontSize: '25px'
  },
  
  textHeaderTableCarbonCredits:{
    color: theme.palette.text.dark,
    // color:theme.palette.text.tertiary,
    fontFamily: 'MontserratSemiBold',
  },
  textBodyTableCarbonCredits:{
    color: theme.palette.text.nonary,
    fontFamily: 'Montserrat',

  },
  tableCarbonCredit: {
    border: `1px solid ${theme.palette.borderSelect.borderDark}`,
    borderCollapse: 'collapse',
  },

  columnCarbonCredit:{
    border: `1px solid ${theme.palette.borderSelect.borderDark}!important `,
  },
  columnCarbonCreditHeader:{
    border: `1px solid ${theme.palette.borderSelect.borderDark}!important `,
    backgroundColor: theme.palette.background.dark
  },
  helperTextCarbonCredits:{
    width: '100%',
    color: theme.palette.text.dark,
    fontFamily: 'MontserratRegular',
    fontSize:14,
    textAlign:'center',

  },
  iconOpenCarbonCredits:{
    padding: 0,
     margin: 0, 
     marginLeft: 6
  }
}));

export default useStyles;
