import React from 'react';
import {
  Container,
  Grid,
  OutlinedInput,
  InputAdornment,
  Button,
  FormHelperText,
} from '@material-ui/core';
import Footer from '../footer/Footer';
import {
  CarbonCreditsSearcherLogic,
  useStyles,
  CarbonCreditsSearcherService,
} from './carbonCreditsSearcher.module';
import SearchIcon from '@material-ui/icons/Search';
import TableCarbonCreditsSearcher from './TableCarbonCreditsSearcher';

const CarbonCreditsSearcher = () => {
  const service = CarbonCreditsSearcherService();

  const {
    values,
    searchValue,
    handleInputChange,
    isButtonEnabled,
    handleSubmit,
    translator,
    errorMessage,
  } = CarbonCreditsSearcherLogic(service.getNfts);

  const classes = useStyles({ isButtonEnabled });

  return (
    <>
      <Container maxWidth="md" className={classes.containerCarbonCredits}>
        <Grid container spacing={2} alignItems="center">
          <Grid item lg={10} md={9} sm={9} xs={12}>
            <OutlinedInput
              className={classes.inputCarbonCredits}
              variant="outlined"
              fullWidth
              placeholder={translator('carbonCredits.search')}
              value={searchValue}
              onChange={handleInputChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item lg={2} md={3} sm={3} xs={12}>
            <Button
              className={classes.buttonCarbonCredits}
              fullWidth
              disabled={!isButtonEnabled}
              onClick={handleSubmit}
            >
              {translator('carbonCredits.button')}
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center">
          <Grid item lg={10} md={9} sm={9} xs={12}>
            <FormHelperText className={classes.helperTextCarbonCredits}>
              {translator('carbonCredits.isValidPattern')}
            </FormHelperText>
          </Grid>

          <Grid item lg={2} md={3} sm={3} xs={12}></Grid>
        </Grid>
        {errorMessage ? (
          <Grid container spacing={2} alignItems="center">
            <Grid item lg={10} md={9} sm={9} xs={12}>
              <FormHelperText className={classes.helperTextCarbonCredits}>
                {errorMessage}
              </FormHelperText>
            </Grid>

            <Grid item lg={2} md={3} sm={3} xs={12}></Grid>
          </Grid>
        ) : (
          values && <TableCarbonCreditsSearcher data={values} />
        )}
      </Container>

      <Footer />
    </>
  );
};

export default CarbonCreditsSearcher;
